<template>
  <div>
    <!-- Mobile layout -->
    <responsive-layout
      v-if="$vuetify.breakpoint.mobile"
      poster="https://vod.eventcdn.net/pandora/smc2021/TourDePandora_Bg.png"
    >
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        dark
      >
        <div class="main py-8 flex-fill">
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="12"
                class="pl-0 d-flex align-start justify-start flex-column ml-1"
              >
                <v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0 mb-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>
                <div class="route-name-background-container-information ml-2">
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <v-row justify="start" align="start" class="mt-0">
              <v-col cols="12" class="pt-1 pl-2 pr-6">
                <div
                  class="
                    tour-de-pandora-desc-container
                    d-flex
                    justify-start
                    align-start
                    pa-3
                    ml-1
                  "
                >
                  <p class="tour-de-pandora-router-desc-text white--text mb-0">
                    Choose a destination
                    <small v-if="event.state !== 'ONDEMAND'"
                      >(25 pts pr. visited destination)</small
                    >
                  </p>
                </div>
              </v-col>
            </v-row>

            <!-- Downloads section -->
            <v-row
              justify="start"
              align="start"
              class="mt-2 sessions-agenda-lobby-row"
            >
              <!-- Boardingpasses image section -->
              <v-col
                cols="12"
                class="py-6"
                v-for="location in locations"
                :key="location.id"
              >
                <router-link
                  :to="{
                    name: 'Destination',
                    params: {
                      id: location.id,
                    },
                  }"
                >
                  <div style="position: relative">
                    <v-img
                      contain
                      :src="
                        location.visited
                          ? location.boarding_pass_visited
                          : location.boarding_pass
                      "
                      max-width="100%"
                      style="max-width: 100%"
                      :alt="location.name"
                    ></v-img>

                    <v-btn
                      x-small
                      color="background"
                      dark
                      tile
                      elevation="0"
                      v-if="location.visited"
                      absolute
                      top
                      right
                      style="z-index: 2; top: 60px; right: 30px"
                      >VISIT AGAIN</v-btn
                    >
                  </div>
                </router-link>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout
      v-else
      poster="https://vod.eventcdn.net/pandora/smc2021/backgrounds/TourDePandora_loop.jpg"
      background-video-initial="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/TourDePandora.mp4"
      background-video-loop="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/loop/TourDePandora_loop.mp4"
      #default="{ replayBackgroundVideo, isLooping }"
    >
      <v-sheet class="d-flex flex-column transparent" light>
        <div class="main">
          <template v-if="isLooping">
            <div style="position: fixed; right: 541px; top: 75%">
              <div style="position: relative" class="d-flex align-center">
                <v-btn
                  color="black"
                  class="play-btn pr-7"
                  style="position: unset"
                >
                  <span class="white--text play-btn-text">WELCOME</span>
                </v-btn>

                <v-btn
                  fab
                  class="rounded-circle ml-n4"
                  color="white"
                  @click="replayBackgroundVideo"
                  style="z-index: 16"
                >
                  <v-icon dark color="pink"> fas fa-play </v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <v-container fluid>
            <!-- 'Breadcrumb' navigation at the top -->
            <v-row justify="start" align="start" class="mt-5 lobby-router-row">
              <v-col
                cols="8"
                class="pl-0 d-flex align-start justify-start ml-10"
              >
                <v-btn
                  class="ma-2 white--text lobby-back-button rounded-0 mr-0"
                  color="black"
                >
                  <router-link class="white--text" to="/lobby">
                    <v-icon left dark color="pink" class="back-arrow-theatre">
                      fas fa-chevron-left
                    </v-icon>
                    <span class="back-arrow-lobby-link">To lobby</span>
                  </router-link>
                </v-btn>
                <div
                  class="
                    route-name-background-container-meet-your-colleagues
                    mt-2
                  "
                >
                  <span class="theatre-route-name">{{ $route.meta.name }}</span>
                </div>
              </v-col>
            </v-row>

            <v-row justify="start" align="start" class="lobby-router-row ml-5">
              <v-col cols="8" class="pt-0 d-flex">
                <div
                  class="
                    tour-de-pandora-desc-container
                    d-flex
                    justify-start
                    align-center
                    pa-4
                    ml-1
                  "
                >
                  <p
                    class="
                      tour-de-pandora-router-desc-text
                      white--text
                      mb-0
                      ml-5
                    "
                  >
                    Choose a destination
                    <small v-if="event.state !== 'ONDEMAND'"
                      >(25 pts pr. visited destination)</small
                    >
                  </p>
                </div>
              </v-col>
            </v-row>

            <!-- Boardingpasses image section -->
            <v-row
              justify="start"
              align="start"
              class="mt-2 sessions-agenda-lobby-row ml-0"
            >
              <v-col cols="4">
                <v-col cols="12" class="pb-0 pr-0 pl-0 mt-4">
                  <div class="py-6 d-flex pl-2 pr-3">
                    <v-row>
                      <v-col
                        v-for="location in locations"
                        :key="location.id"
                        cols="12"
                        class="mt-2 mb-2 d-flex align-center pa-0 pb-8"
                      >
                        <router-link
                          :to="{
                            name: 'Destination',
                            params: {
                              id: location.id,
                              visited: location.visited,
                            },
                          }"
                        >
                          <div style="position: relative">
                            <v-col cols="12" class="d-flex mr-1 pa-0">
                              <v-img
                                :src="
                                  location.visited
                                    ? location.boarding_pass_visited
                                    : location.boarding_pass
                                "
                                style="max-width: 100%"
                                :alt="location.name"
                              ></v-img>

                              <v-btn
                                small
                                color="background"
                                dark
                                tile
                                elevation="0"
                                v-if="location.visited"
                                style="
                                  z-index: 2;
                                  position: absolute;
                                  top: 115px;
                                  right: 60px;
                                "
                                >VISIT AGAIN</v-btn
                              >
                            </v-col>
                          </div>
                        </router-link>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-col>
            </v-row>

            <!-- Hidden questions -->
            <AppHiddenQuestion
              top="36%"
              right="7.6%"
              question="What date does the Pandora Me reset happen? "
              :options="['September 23rd', 'October 1st', 'September 30th']"
              answer="September 30th"
              :tracking-id="31"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="53.5%"
              right="37.5%"
              question="In 2020, what percentage of new shoppers were acquired through BOPIS?"
              :options="['84%', '16%', '50%']"
              answer="84%"
              :tracking-id="26"
            ></AppHiddenQuestion>

            <AppHiddenQuestion
              top="76%"
              right="3%"
              :tracking-id="32"
            ></AppHiddenQuestion>
          </v-container>
        </div>
      </v-sheet>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
import boardingPassImageData from "../assets/data/boardingcards-data.json";
import downloadsMockData from "../assets/data/information-downloads-mock-data.json";
import axios from "axios";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      dialog: false,
      column: null,
      row: null,
      overlay: false,
      boardingPassImageData: boardingPassImageData,
      downloadsMockData: downloadsMockData,
      locations: [],
    };
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters(["me"]),
  },

  async mounted() {
    await this.getLocations();
  },

  watch: {
    "$store.getters.isAuthenticated": async function (newValue, oldValue) {
      console.log("isAuthenticated change:", newValue, "oldValue:", oldValue);
      if (newValue) {
        await this.onLogin();
      }
    },
  },

  methods: {
    navigate() {
      const next = this.$route.query?.next;
      if (next) {
        this.$router.push(next);
      } else if (this.$store.getters.isAdmin) {
        this.$router.push({ name: "AdminHome" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async login() {
      this.error = false;
      try {
        await this.$store.dispatch("login", this.credentials);
      } catch (e) {
        this.error = true;
        console.debug("Login error:", e);
      }
    },
    async onLogin() {
      // Navigate
      this.navigate();
    },
    async getLocations() {
      const locationId = this.$route.params.id;
      const url = `${process.env.VUE_APP_API_URL}locations/`;

      // Efter at kunden har set videoen
      // POST - url (locations/id)

      const { data } = await axios.get(url);

      this.locations = data;
    },
  },
};
</script>

<style scoped>
/*.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/TourDePandora_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background-image: url("https://vod.eventcdn.net/pandora/smc2021/TourDePandora_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}*/

.v-application.livehouse-app .countdown-value {
  font-size: 5rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}

.countdown-with-pink-text {
  color: var(--pink) !important;
  font: var(--unnamed-font-style-normal) normal bold 80px/60px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 80px/60px Pan Text;
}

.countdown-headline {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/32px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 22px/32px Pan Text;
}

.unit {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-22) var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--darker-grey);
  text-align: center;
  font: normal normal normal 18px/22px Pan Text;
}

.countdown-container {
  margin-top: 10.5rem;
}
</style>
